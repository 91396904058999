import Decimal from 'decimal.js'
import { computed, reactive, ref } from 'vue'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import api from '/~/core/api'
import { roundFigure } from '/~/utils/format'
import { useExtensions } from '/~/composables/extensions'
import { useLogger } from '/~/composables/logger'
import { usePoints } from '/~/composables/points'
import { useSearch } from '/~/composables/search'
import { useUser } from '/~/composables/user'

const { registerAlgoliaSearch } = useSearch()
const { getConfigByName, getManifestByName } = useExtensions()

const logger = useLogger('eStore')

const TRAVEL_MODULE_NAME = 'travel'

const algolia = reactive(new AlgoliaWorker())
const destinations = ref(null)
const searchQuery = ref('')
const selectedCategory = ref(null)
const loading = ref(false)

const config = computed(() => getConfigByName(TRAVEL_MODULE_NAME))
const module = computed(() => getManifestByName(TRAVEL_MODULE_NAME))
const index = computed(() => getDefaultIndex(config.value))
const label = computed(() => module.value?.label ?? 'Travel')

const returnAllocatedAs = computed(() => config.value?.allocated_as)
const isCashbackReturn = computed(() => returnAllocatedAs.value === 'cashback')
const isPointsReturn = computed(() => returnAllocatedAs.value === 'points')
const pointsReturn = computed(() => {
  const { earnPointsRate } = usePoints()

  return new Decimal(100).div(earnPointsRate.value.purchase).toNumber()
})
const pointsReturnLabel = computed(() => {
  const { pointsLabel } = usePoints()

  return `${Math.floor(pointsReturn.value)} ${pointsLabel.value}`
})

const membershipLabels = computed(() =>
  (config.value?.membershipLabels ?? []).filter(Boolean)
)
const isEnabled = computed(() => {
  if (!config.value) {
    console.error('no config for Travel module')
    return false
  }

  const { user } = useUser()
  const isMembershipEnabled =
    membershipLabels.value.length === 0 ||
    membershipLabels.value.includes(user.value.membershipLabel)

  if (!isMembershipEnabled) {
    logger.debug(
      `Travel module is disabled for membership ${user.value.membershipLabel}`
    )
    return false
  }

  return true
})

const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)

const routeState = computed(() => {
  const routeState = {
    query: {},
    params: {
      category: selectedCategory.value,
    },
  }

  if (searchQuery.value) {
    routeState.query.search = searchQuery.value
  }
  return routeState
})

const defaultFilters = computed(() =>
  [config.value.filters || config.value.filter].filter((v) => v).join(' AND ')
)

const isFiltersSelected = computed(() => {
  return Boolean(
    searchQuery.value !== '' ||
      (selectedCategory.value &&
        selectedCategory.value !== destinations.value?.[0].id)
  )
})
const ready = computed(() => Boolean(destinations.value))

function reset() {
  algolia.reset()
  searchQuery.value = ''
  selectedCategory.value = null
}

function resetFilters() {
  searchQuery.value = ''
  selectedCategory.value = null
}

function initTravel() {
  if (!isEnabled.value) {
    return
  }

  algolia.setParams(config.value)

  const filters = [defaultFilters.value].filter((v) => v)

  registerAlgoliaSearch({
    label: 'Travel Packages',
    order: 1,
    config: config.value,
    algolia: {
      params: {
        filters: filters.join(' AND '),
        attributes: ['package_id', 'name', 'site_id', 'hero_image'],
      },
    },
    mapping: (item) => {
      return {
        id: item.id,
        image: item.hero_image,
        label: item.name,
        target: {
          name: 'travel-details',
          params: {
            site: item.site_id,
            slug: item.package_id,
          },
        },
      }
    },
  })
}

function syncState({ to }) {
  reset()
  selectedCategory.value = to.params?.category ?? null
  searchQuery.value = to.query?.search ?? ''
}

async function getContent() {
  if (!algolia.isSet) {
    initTravel()
  }

  // NOTE: reset items to trigger processing in core/algolia
  algolia.reset()

  const facetFilters = []

  if (selectedCategory.value) {
    const destination = algolia.decodePath(selectedCategory.value)

    facetFilters.push(`destination:${destination}`)
  }

  const filters = [defaultFilters.value].filter((v) => v)

  await algolia.getDataMultiple({
    multiple: [
      {
        indexName: index.value,
        query: (searchQuery.value || '').trim(),
        params: {
          filters: filters.join(' AND '),
          facetFilters,
          distinct: true,
        },
      },
      {
        indexName: index.value,
        params: {
          filters: filters.join(' AND '),
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['destination'],
        },
      },
    ],
  })

  const [categoriesResult] = algolia.multiple

  const categoriesFacets = algolia.parseFacets(categoriesResult)

  if (categoriesFacets) {
    const algoliaDestinations = categoriesFacets.get('destination')

    if (algoliaDestinations) {
      // move Other Countries to end of the list
      const otherItemIndex = algoliaDestinations.values.findIndex((country) => {
        return country.id === 'other-countries'
      })

      if (otherItemIndex >= 0) {
        const otherItem = algoliaDestinations.values.splice(
          otherItemIndex,
          1
        )[0]

        otherItem.label = 'Other'
        algoliaDestinations.values.push(otherItem)
      }

      destinations.value = algoliaDestinations.values

      destinations.value.unshift({
        label: 'All Destinations',
        count: algoliaDestinations.total,
        id: null,
      })
    }
  }
}

function enquireAboutPackage(submitData) {
  if (submitData) {
    return api.post('/v3/campaign/travel', submitData).then(({ data }) => data)
  }
}

function fetchTravelPackage(packageId: string) {
  return api.get(`/v3/travel-packages/${packageId}`)
}

export function useTravel() {
  return {
    algolia,
    ready,
    isEnabled,
    index,
    loading,
    routeState,
    destinations,
    selectedCategory,
    isFiltersSelected,
    searchQuery,
    searchGroup,

    initTravel,
    syncState,
    resetFilters,
    getContent,
    enquireAboutPackage,
    fetchTravelPackage,
    isCashbackReturn,
    isPointsReturn,
    pointsReturnLabel,
  }
}
